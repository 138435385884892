import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pic = _resolveComponent("Pic")

  return (Number(_ctx.rank) < 4)
    ? (_openBlock(), _createBlock(_component_Pic, {
        key: 0,
        width: _ctx.size,
        height: _ctx.size,
        src: `cup/wt/cup_${_ctx.suffix}`
      }, null, 8, ["width", "height", "src"]))
    : (_openBlock(), _createBlock("span", _hoisted_1, _toDisplayString(_ctx.rank), 1))
}