
import Pic from '@/components/Pic.vue'
import TheHistory from '@/modules/weTradeCup/components/TheHistory.vue'
import TheRankNumber from '@/modules/weTradeCup/components/TheRankNumber.vue'
import { rules, CupType } from '@/modules/weTradeCup/weTradeCup'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheCupTabSwitch from '@/modules/weTradeCup/components/TheCupTabSwitch.vue'
import { defineComponent, shallowRef } from 'vue'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'PrizeDetail',
  components: { TheRankNumber, TheHistory, Pic, PageWithHeader, TheCupTabSwitch },
  props: {
    tab: String,
    type: String,
  },
  setup (props) {
    const useRule = shallowRef(props.tab !== '1')
    const tabIndex = shallowRef(props.tab ? Number(props.tab || 0) : 0)

    const type = props.type ?? CupType.WEEKLY
    const rule = rules[type as CupType]
    const context = ['--', 'cupprizes_9', 'cupprizes_10', 'cupprizes_11'][props.type ? +props.type : 0]

    return {
      useRule,
      rule,
      translate,
      context,
      tabIndex,
    }
  },
})
