/**
 * @author 阿佑[ayooooo@petalmail.com]
 * @date 2022/4/27 15:40
 * @description
 *   weTradeCup.ts of WeTrade
 */
import { openDialog } from '@/components/popup/popup'
import OpenOperationDialog from '@/modules/weTradeCup/views/OpenOperationDialog.vue'
import { isPublic } from '@/modules/weTradeCup/weTradeCup.api'
import { YesOrNo } from '@/types'

export enum CupType {
  WEEKLY = 1,
  MONTHLY,
  SEASON,
}

export const rules = {
  [CupType.WEEKLY]: [
    { rank: 1, goldPrize: 5, moneyPrize: 3000000 },
    { rank: 2, goldPrize: 2, moneyPrize: 2000000 },
    { rank: 3, goldPrize: 1, moneyPrize: 1000000 },
    { rank: '4-10', goldPrize: 1, moneyPrize: 500000 },
    { rank: '11-20', goldPrize: 1, moneyPrize: 200000 },
  ],
  [CupType.MONTHLY]: [
    { rank: 1, goldPrize: 10, moneyPrize: 8000000 },
    { rank: 2, goldPrize: 5, moneyPrize: 6000000 },
    { rank: 3, goldPrize: 2, moneyPrize: 4000000 },
    { rank: '4-10', goldPrize: 1, moneyPrize: 2000000 },
    { rank: '11-20', goldPrize: 1, moneyPrize: 1000000 },
    { rank: '21-30', goldPrize: 1, moneyPrize: 500000 },
  ],
  [CupType.SEASON]: [
    { rank: 1, goldPrize: 20, moneyPrize: 20000000 },
    { rank: 2, goldPrize: 10, moneyPrize: 10000000 },
    { rank: 3, goldPrize: 5, moneyPrize: 6000000 },
    { rank: '4-10', goldPrize: 2, moneyPrize: 4000000 },
    { rank: '11-20', goldPrize: 1, moneyPrize: 2000000 },
    { rank: '21-30', goldPrize: 1, moneyPrize: 1000000 },
    { rank: '31-50', goldPrize: 1, moneyPrize: 500000 },
  ],
}

export const promptOpenOperation = (cb?: (opened: boolean) => void) => {
  let opened = false

  const prompt = () => {
    openDialog(OpenOperationDialog, {
      onConfirm: () => {
        opened = true
        cb?.(opened)
      },
      onCancel: () => cb?.(opened),
    })
  }

  isPublic().then(resp => {
    opened = resp.isPublic === YesOrNo.YES

    if (opened) {
      cb?.(opened)
    } else {
      prompt()
    }
  })

  return prompt
}

export const cupDateFormat = (d: string) => {
  if (!d) {
    return '--'
  }
  const date = new Date(d)
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
    'October', 'November', 'December',
  ]

  return months[date.getMonth()] + '   ' + date.getDate()
}
