/**
 * @author 阿佑[ayooooo@petalmail.com]
 * @date 2022/4/27 11:32
 * @description
 *   weTradeCup.api.ts of WeTrade
 */
import requestResult from '@/common/request/request'
import { TradeDirection, YesOrNo } from '@/types'

export type Prize = {
  rank: number;
  avatar: string;
  name: string;
  rate: number;
}

export type HistoryData = {
  startDate: string;
  endDate: string;
  results: Prize[];
}

export const readHistory = requestResult<HistoryData>('/api/cup/result')

export type Order = {
  contactCode: string;
  createPrice: string;
  buyType: TradeDirection;
  icon: string;
}

export type Master = {
  name: string;
  avatar: string;
  follow: YesOrNo;
  orderInfoModels: Order[];
  orderNumbers: number;
  rank: number;
  rate: number;
  startDate: string;
  endDate: string;
  userId: number;
  profitability: number;
}

export type TGiftItem = {
  actualItemNumber: number;
  actualItemStatus: number;
  prizeAmount: number;
  prizeStatus: number;
  type: number;
  startDate: string;
  endDate: string;
  rank: number;
  rate: number;
}

export const readMasters = requestResult<Master[]>('/api/cup/master')

export const readMasterOrders = requestResult<Master>('/api/cup/master/order')

export const follow = requestResult('/api/cup/master/follow')

export const unfollow = requestResult('/api/cup/master/unfollow')

export const openIt = requestResult('/api/cup/master/public')

export const isPublic = requestResult<{ isPublic: YesOrNo }>('/api/cup/master/public/info')

export const readMyPrize = requestResult<TGiftItem[]>('/api/cup/gift')

export const readMyRank = requestResult<Prize>('/api/cup/result/mine')
