
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheRankNumber',
  components: { Pic },
  props: {
    rank: {
      type: [Number, String],
      required: true,
    },
    sm: Boolean,
    byDesc: Boolean,
  },
  computed: {
    suffix (): string {
      const desc = ['', 'gold', 'silver', 'copper']
      const name = desc[this.rank as keyof typeof desc] || 'other'
      return (this.byDesc ? name : this.rank) as string
    },
    size (): number {
      return this.sm ? 16 : 32
    },
  },
})
