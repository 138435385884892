
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { openIt, readMyPrize } from '@/modules/weTradeCup/weTradeCup.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'OpenOperationDialog',
  components: { Button, DialogTemplate, Pic },
  props: {},
  setup (props, ctx) {
    const [open, progress] = useRequest(openIt)
    const context = shallowRef()
    readMyPrize().then(res => {
      context.value = res.length === 0 ? translate('cup_30') : translate('cup_31')
    })

    return {
      open: () => {
        open()
          .then(() => {
            ctx.emit('confirm')
            ctx.emit('close')
          })
          .catch(() => {
            ctx.emit('cancel')
          })
      },
      progress,
      context,
    }
  },
})
